import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface Props {
  title?: string;
  description?: string;
  path?: string;
  image?: string;
}

const CORE_TWITTER = "@CORE_Innovation";

const SEO: React.FC<Props> = (props) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          description
          baseUrl
        }
      }
    }
  `);

  const defaults = data.site.siteMetadata;

  if (defaults.baseUrl === "" && typeof window !== "undefined") {
    defaults.baseUrl = window.location.origin;
  }

  if (defaults.baseUrl === "") {
    console.error("Please set a baseUrl in your site metadata!");
    return null;
  }

  const title = props.title
    ? props.title + " | " + defaults.title
    : defaults.title;
  const description = props.description || defaults.description;
  const url = new URL(props.path || "", defaults.baseUrl);
  const image = props.image ? new URL(props.image, defaults.baseUrl) : false;

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="canonical" href={url.toString()} />
      <meta name="description" content={description} />
      {image && <meta name="image" content={image.toString()} />}

      <meta property="og:url" content={url.toString()} />
      <meta property="og:type" content="article" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      {image && <meta property="og:image" content={image.toString()} />}

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={CORE_TWITTER} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {image && <meta name="twitter:image" content={image.toString()} />}
    </Helmet>
  );
};

export default SEO;
